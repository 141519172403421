import { EvaluationResult } from '@sharefile/entitlements-plugin-sdk';
import {
	MediaType,
	SolutionCatalogType,
	SolutionCategory,
} from '@sharefile/packaging-types';

export type GetSolutionParams = {
	id: string;
};

export type DeleteSolutionParams = {
	id: string;
};

export type ListSolutionsParamsLegacy = {
	parentRID?: string;
	type?: string;
	parentRIDPrefix?: string;
};

export type CreateExecutionParams = {
	solutionId: string;
	/**
	 * Container under which the resulting resource should get created (generally the account RID)
	 */
	parentRID: string;
	args?: object;
	uninstall?: boolean;
};

export type GetExecutionParams = {
	executionId: string;
};

export type SolutionsSdkConfiguration = {
	baseUrl: string;
};

export interface InstallationInfo {
	version: string;
	params?: any;
	status: SolutionInstallationStatus;
	installationRID: string;
}

export enum SolutionInstallationStatus {
	Requested = 'requested',
	Acknowledged = 'acknowledged',
	Running = 'running',
	Success = 'success',
	Failed = 'failed',
	Rollback_Processing = 'rollback-processing',
	Rollback_Failed = 'rollback-failed',
	Rollback_Succeeded = 'rollback-succeeded',
	Rollback_Requested = 'rollback-requested',
	AwaitingUpload = 'awaiting-upload',
	Uninstall_Requested = 'uninstall-requested',
	Uninstall_Running = 'uninstall-running',
	Uninstall_Success = 'uninstall-success',
}

export type BaseMediaInfo = {
	type: MediaType;
	src: string;
};

export type ImageInfo = BaseMediaInfo & {
	type: MediaType.Image;
	alt?: string;
};

export type MediaInfo = ImageInfo;
/**
 * A solution information item.
 * @interface SolutionInfoLegacy
 */
export interface SolutionInfoLegacy {
	id: string;
	version: string;
	containerRID: string;
	rootType: string;
	created: string;
	creator: string;
	modified: string;
	modifier: string;
	namespace: string;
	name: string;
	label: string;
	description?: string;
	publicationStatus: any;
	installationInfo?: InstallationInfo;
	latestVersion?: string;
}

export type SolutionInfoCompatible = SolutionInfoLegacy | SolutionInfo;

/**
 * A solution item. including definition
 * @export
 * @interface Solution
 */
export type Solution = {
	id: string;
	version: string;
	containerRID: string;
	definition: any;
	hero?: ImageInfo;
	rootType?: string;
	created: string;
	creator: string;
	modified?: string;
	modifier?: string;
	namespace?: string;
	name?: string;
	publicationStatus: any;
};

export type SolutionCategoryOption = {
	value: string;
	label: string;
};

export type Execution = {
	id: string;
	packageId: string;
	version: string;
	containerRID: string;
	created: string;
	creator: string;
	expires: number;
	status: ExecutionStatus;
	updated: string;
};

export enum ExecutionStatus {
	Requested = 'requested',
	Running = 'running',
	Success = 'success',
	Failed = 'failed',
	Rollback_Processing = 'rollback-processing',
	Rollback_Failed = 'rollback-failed',
	Rollback_Succeeded = 'rollback-succeeded',
	Rollback_Requested = 'rollback-requested',
}

export type CreateExecutionResponse = Pick<Execution, 'id'>;

// v2 types

export interface PublisherInfo {
	id: string;
	namespace: string;
	name: string;
	description?: string;
	created: string;
	websiteUrl?: string;
	contactEmail?: string;
	logo?: string;
}

export interface SolutionInfo {
	RID: string;
	id: string;
	version: string;
	name: string;
	description: string;
	hero?: ImageInfo;
	carousel?: MediaInfo[];
	overview?: string;
	containerRID: string;
	created: string;
	creator: string;
	namespace: string;
	latestVersion?: string;
	params?: any;
	installationInfo?: InstallationInfo;
	deleted?: boolean;
	label: string;
	categories?: SolutionCategory[];
	catalogType: SolutionCatalogType;
	navigationPath?: string;
	entitlement?: boolean;
	entitlementData?: EvaluationResult;
	publisher?: PublisherInfo;
}

export type ListSolutionsParams = {
	containerRID?: string;
	namespace?: string;
};

export type InstallSolutionParams = {
	solutionId: string;
	parentRID: string;
	args?: any;
	createEntitlement?: boolean;
};

export type RequestInstallSolutionArgs = {
	comments?: string;
	sendCopyToSender?: boolean;
};

export type RequestInstallSolutionParams = {
	solutionRID: string;
	args?: RequestInstallSolutionArgs;
};

export type UpdateSolutionParams = {
	solutionId: string;
	parentRID: string;
	args?: any;
};

export type UninstallSolutionParams = {
	solutionId: string;
	containerRID: string;
	deletionPolicy: DeletionPolicy;
};

export enum DeletionPolicy {
	Delete = 'Delete',
	Retain = 'Retain',
}

export type InstallSolutionResponse =
	| {
			id: string;
	  }
	| EvaluationResult;

export type UpdateSolutionResponse =
	| {
			id: string;
	  }
	| EvaluationResult;

export type UninstallSolutionResponse = {
	id: string;
};

export type GetInstallationParams = {
	id: string;
};

enum AdditionalInstallationStatus {
	AwaitingUpload = 'awaiting-upload',
	Uninstall_Requested = 'uninstall-requested',
	Uninstall_Running = 'uninstall-running',
	Uninstall_Success = 'uninstall-success',
}

export type InstallationStatus = ExecutionStatus | AdditionalInstallationStatus;

export type ErrorResponse = {
	message: string;
	stack?: any;
	packageExecutionId?: string;
	details?: any;
};

export type GetInstallationResponse = {
	id: string;
	solutionId: string;
	containerRID: string;
	payload: object;
	status: InstallationStatus;
	error?: Record<string, any> | ErrorResponse;
	created: string;
	creator: string;
	expires?: number;
	states?: any;
	executionIds?: string[];
	upgradeStatus?: string;
};

export type GetInstallationLogsParams = {
	executionId: string;
};

export type RunLog = {
	entry: {
		level: string;
		message: string;
		meta?: {
			[name: string]: any;
		};
	};
	timestamp: number;
};

export type GetInstallationLogsResponse = RunLog[];

export type GetInstallationEntitlementParams = {
	catalogType: SolutionCatalogType;
};

export type GetInstallationEntitlementResponse = EvaluationResult;
